@use "@angular/material" as mat;

@import "src/styles/_constants.scss";

vts-sidenav-menu .mat-mdc-list-item {
  border-radius: 0 28px 28px 0 !important;

  $primary: map-get($vts-light-theme, "primary");
  $foreground: map-get($vts-light-theme, "foreground");

  &.router-active {
    background-color: #b2dfdb !important;

    .mat-icon,
    .mat-mdc-list-item-title {
      color: mat.m2-get-color-from-palette($primary) !important;
    }
  }

  .mat-icon,
  .mat-mdc-list-item-title {
    color: mat.m2-get-color-from-palette($foreground, "text") !important;
  }
}

.dark vts-sidenav-menu .mat-mdc-list-item {
  $primary: map-get($vts-dark-theme, "primary");
  $foreground: map-get($vts-dark-theme, "foreground");

  &.router-active {
    background-color: #004d40 !important;

    .mat-icon,
    .mat-mdc-list-item-title {
      color: #80cbc4 !important;
    }
  }

  .mat-icon,
  .mat-mdc-list-item-title {
    color: mat.m2-get-color-from-palette($foreground, "text") !important;
  }
}
