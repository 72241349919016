@switch (status) {
  @case ("checking") {
    <mat-icon
      class="mat-secondary-text !w-4 !h-4 animate-spin"
      title="Checking for updates"
      svgIcon="sync"
    />
  }
  @case ("failed") {
    <mat-icon
      class="mat-secondary-text !w-4 !h-4"
      title="Failed to check for updates"
      svgIcon="sync_problem"
    />
  }
  @case ("updated") {
    <mat-icon
      class="mat-secondary-text !w-4 !h-4"
      title="Up to date"
      svgIcon="check_circle"
    />
  }
  @case ("outdated") {
    <mat-icon
      class="mat-secondary-text !w-4 !h-4"
      title="New update available"
      svgIcon="arrow_circle_down"
    />
  }
}
