<div class="w-64 min-h-full flex flex-col mr-4">
  <mat-nav-list class="flex-1">
    <vts-sidenav-menu
      i18n-headline="@@channel"
      headline="channel"
      [items]="channelsItems"
    />
    <mat-divider class="!mr-2 !my-2" />
    <vts-sidenav-menu
      i18n-headline="@@stream"
      headline="stream"
      [items]="streamsItems"
    />
    <mat-divider class="!mr-2 !my-2" />
    <vts-sidenav-menu [items]="extraItems" />
  </mat-nav-list>

  <div class="p-4 mat-small mat-secondary-text">
    <a
      class="text-inherit leading-5 no-underline"
      href="https://github.com/PoiScript/HoloStats/commit/{{ commitSha }}"
      target="_blank"
    >
      {{ commitSha }}
    </a>
    <vts-update-indicator />
    <br />
    &copy; 2019-2024 PoiScript
  </div>
</div>
