<mat-sidenav-container>
  <mat-sidenav #drawer fixedInViewport>
    <vts-sidenav />
  </mat-sidenav>

  <mat-sidenav-content>
    <vts-header />
    <div
      class="pt-16 min-h-[calc(100vh-4rem)] mx-auto max-w-4xl overflow-x-auto"
    >
      <router-outlet />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

@if (queryService.enableDevtools()) {
  @defer {
    <angular-query-devtools initialIsOpen />
  }
}
